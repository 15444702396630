/* eslint-disable react-hooks/exhaustive-deps */
import { Center, Spacer, Skeleton, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import ExportListItem from './export-list-item'
import { useLanguageContext } from '../../../context/locale.context'
import { Pagination } from '../../../components/pagination'
const ExportTable = ({ exportList, isLoading }) => {
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const {
    state: { locale },
  } = useLanguageContext()

  useEffect(() => {
    if (exportList.length > pageSize) {
      let tempTable = exportList.slice(currentPage - 1, pageSize)
      setTableData(tempTable)
    } else {
      setTableData(exportList)
    }
  }, [isLoading])

  const changePage = page => {
    const startIndex = (page - 1) * pageSize
    const endIndex =
      startIndex + pageSize > exportList.length
        ? exportList.length
        : startIndex + pageSize
    let tempTable = exportList.slice(startIndex, endIndex)
    setTableData(tempTable)
    setCurrentPage(page)
  }
  const onPageSizeChange = newPageSize => {
    const startIndex = 0
    const endIndex =
      startIndex + newPageSize > exportList.length
        ? exportList.length
        : startIndex + newPageSize
    let tempTable = exportList.slice(0, endIndex)
    setTableData(tempTable)
    setCurrentPage(1)
    setPageSize(newPageSize)
  }

  return (
    <>
      <VStack
        maxH="calc(100vh - 130px)"
        minH="calc(100vh - 130px)"
        overflowY="scroll"
        w="full"
        bg="secondary"
        p={2}
      >
        {!!isLoading ? (
          [1, 2, 3, 4, 5].map(i => {
            return (
              <Skeleton
                boxShadow="md"
                borderRadius="md"
                key={i * Math.random()}
                p={2}
                w="100%"
                h="100px"
                mb={1}
              />
            )
          })
        ) : tableData.length > 0 ? (
          tableData.map(row => {
            return <ExportListItem row={row} key={Math.random() * Date.now()} />
          })
        ) : (
          <Center>
            <Text>{locale['No Data Exported']}</Text>
          </Center>
        )}
      </VStack>
      <Spacer />
      {!isLoading && tableData.length > 0 && (
        <Pagination
          limit={pageSize}
          page={currentPage}
          total={exportList.length}
          setlimit={onPageSizeChange}
          setpage={changePage}
        />
      )}
    </>
  )
}

export default ExportTable
