/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useReducer } from 'react'
import Select from 'react-select'
import {
	Box,
	Input,
	Button,
	VStack,
	HStack,
	Text,
	Skeleton,
} from '@chakra-ui/react'

import 'react-datepicker/dist/react-datepicker.css'
import '../export.scss'
import {
	addMonths,
	subMonths,
	startOfDay,
	differenceInMonths,
	endOfDay,
	startOfWeek,
	endOfWeek,
	startOfMonth,
	endOfMonth,
	startOfQuarter,
	endOfQuarter,
	addQuarters,
	subQuarters,
} from 'date-fns'
import { FILTER_TYPE_FETCH, FILTER_TYPE_LIST } from '../../export.const'
import DatePicker from 'react-datepicker'
import {
	useGetApplicationList,
	useGetFilterList,
	useSubApplicationList,
	useClusterList,
	useSegmentList,
	useProcessList,
	useCreaterList,
	useWorkforceList,
	useFetchFormsList,
	useFormFieldList,
	useAssetTagType1,
	useAssetTagType2,
	useAssetTagType3,
	useAssetTag1,
	useAssetTag2,
	useAssetTag3,
	useGetProductCategoryList,
	getReportFilterValue,
} from '../../../../hooks/export.hooks'
import moment from 'moment'
import { useMutation } from 'react-query'
// import {
// 	dataLayerTagManager,
// 	eventLabelTrack,
// 	GA_CATEGORY_EXPORT,
// 	sectionDetailedTrack,
// } from '../../../repository/repo.utils'
import { useLanguageContext } from '../../../../context/locale.context'

const defaultFilterValue = { label: 'All', value: 0 }

const initialFilterdValue = {
	selectedApplication: null,
	selectedSubApplication: null,
	selectedCluster: null,
	selectedBuilding: null,
	selectedCreator: null,
	selectedCategory: defaultFilterValue,
	selectedFamily: defaultFilterValue,
	selectedProduct: defaultFilterValue,
	selectedSegment: null,
	selectedWorkforce: null,
	selectedAccount: null,
	selectedRole: null,
	selectedStatus: null,
	selectedSubstatus: null,
	selectedTagType1: null,
	selectedTagType2: null,
	selectedTagType3: null,
	selectedTag1: null,
	selectedTag2: null,
	selectedTag3: null,
	selectedDateType: null,
	selectedProcess: null,
	selectedTimeline: null,
	selectedForm: [defaultFilterValue],
	selectedField: [defaultFilterValue],
	selectedRecievers: null,
	selectedStartDate: startOfDay(new Date()),
	selectedEndDate: endOfDay(new Date()),
}

const AddExportForm = ({
	deskAssetId,
	cbAddExport,
	cbSaveExport,
	setIsNotValidForm,
	isNotValidForm,
	errMsg,
	setErrMsg,
}) => {
	const { mutate: fetchApplicationListMutate } = useGetApplicationList()
	const {
		state: { locale },
	} = useLanguageContext()

	// All onSuccess callbacks doesn't fire inside mutate.onSuccess if triggered at same time
	// Thus onSuccess defined inside useMutation instead of mutate
	const { mutate: fetchReportFilterValueMutate } = useMutation(
		payload => getReportFilterValue(payload),
		{
			onSuccess: (res, payload) => {
				const { selectedFilter } = payload
				const {
					data: { status, response },
				} = res
				if (status === 200) {
					const prevFiltersOptionList = filtersOptionList
					prevFiltersOptionList[payload.filterList] = response
					setFiltersOptionList(prevFiltersOptionList)
					dispatchFilterdValue({ type: selectedFilter, payload: response[0] })
				}
			},
			onError: async err => {
				console.log(err)
			},
		}
	)

	const { mutate: fectchProcuctCategoryListMutate } =
		useGetProductCategoryList()
	const { mutate: fetchReportFilterListMutate, isLoading } = useGetFilterList()
	const {
		mutate: fetchSubApplicationListMutate,
		isLoading: loadingSubApplication,
	} = useSubApplicationList()
	const { mutate: fetchClusterListMutate } = useClusterList()
	const { mutate: fetchSegmentListMutate } = useSegmentList()
	const { mutate: fetchCreaterListMutate } = useCreaterList()
	const { mutate: fetchProcessListMutate } = useProcessList()
	const { mutate: fetchWorkforceListMutate } = useWorkforceList()
	const { mutate: fetchFormsListMutate } = useFetchFormsList()
	const { mutate: fetchFormsFieldListMutate } = useFormFieldList()
	const { mutate: fetchAssetTagType1Mutate } = useAssetTagType1()
	const { mutate: fetchAssetTagType2Mutate } = useAssetTagType2()
	const { mutate: fetchAssetTagType3Mutate } = useAssetTagType3()
	const { mutate: fetchAssetTag1Mutate } = useAssetTag1()
	const { mutate: fetchAssetTag2Mutate } = useAssetTag2()
	const { mutate: fetchAssetTag3Mutate } = useAssetTag3()

	const [applicationList, setApplicationList] = useState([])
	const [filterList, setFilterList] = useState([])
	const [isSaveStart, setIsSaveStart] = useState(false)
	const [exportName, setExportName] = useState('')
	const [selectedFieldError, setSelectedFieldError] = useState(false)
	const [selectedFormError, setSelectedFormError] = useState(false)
	const [filtersOptionList, setFiltersOptionList] = useState({
		applicationList: [],
		buildingList: [],
		categoryList: [],
		clusterList: [],
		creatorList: [],
		dateTypeList: [],
		familyList: [],
		formFieldsList: [],
		processFormsList: [],
		processList: [],
		productList: [],
		segmentList: [],
		subApplicationList: [],
		subStatusList: [],
		roleList: [],
		statusList: [],
		timelineList: [],
		workforceTagList: [],
		assetTagType1: [],
		assetTagType2: [],
		assetTagType3: [],
		assetTag1: [],
		assetTag2: [],
		assetTag3: [],
	})

	const isAllSelected = value => {
		return value.some(form => {
			if (!!form) {
				return form.value === 0
			} else {
				return false
			}
		})
	}

	const reducerFilterdValue = (state, action) => {
		const { payload } = action
		let newState
		switch (action.type) {
			case 'selectedApplication':
				newState = { ...state, selectedApplication: payload }
				break
			case 'selectedSubApplication':
				newState = { ...state, selectedSubApplication: payload }
				break
			case 'selectedCluster':
				newState = { ...state, selectedCluster: payload }
				break
			case 'selectedBuilding':
				newState = { ...state, selectedBuilding: payload }
				break
			case 'selectedCreator':
				newState = { ...state, selectedCreator: payload }
				break
			case 'selectedCategory':
				newState = { ...state, selectedCategory: payload }
				break
			case 'selectedFamily':
				newState = { ...state, selectedFamily: payload }
				break
			case 'selectedProduct':
				newState = { ...state, selectedProduct: payload }
				break
			case 'selectedSegment':
				newState = { ...state, selectedSegment: payload }
				break
			case 'selectedWorkforce':
				newState = { ...state, selectedWorkforce: payload }
				break
			case 'selectedAccount':
				newState = { ...state, selectedAccount: payload }
				break
			case 'selectedRole':
				newState = { ...state, selectedRole: payload }
				break
			case 'selectedStatus':
				newState = { ...state, selectedStatus: payload }
				break
			case 'selectedSubstatus':
				newState = { ...state, selectedSubstatus: payload }
				break
			case 'selectedTagType1':
				newState = { ...state, selectedTagType1: payload }
				break
			case 'selectedTagType2':
				newState = { ...state, selectedTagType2: payload }
				break
			case 'selectedTagType3':
				newState = { ...state, selectedTagType3: payload }
				break
			case 'selectedTag1':
				newState = { ...state, selectedTag1: payload }
				break
			case 'selectedTag2':
				newState = { ...state, selectedTag2: payload }
				break
			case 'selectedTag3':
				newState = { ...state, selectedTag3: payload }
				break
			case 'selectedDateType':
				newState = { ...state, selectedDateType: payload }
				break
			case 'selectedProcess':
				newState = { ...state, selectedProcess: payload }
				break
			case 'selectedTimeline':
				newState = { ...state, selectedTimeline: payload }
				break
			case 'selectedForm':
				newState = { ...state, selectedForm: payload }
				break
			case 'selectedField':
				newState = { ...state, selectedField: payload }
				break
			case 'selectedRecievers':
				newState = { ...state, selectedRecievers: payload }
				break
			case 'selectedStartDate':
				newState = { ...state, selectedStartDate: payload }
				break
			case 'selectedEndDate':
				newState = { ...state, selectedEndDate: payload }
				break
			case 'dateType':
				if (
					payload.selectedOption.value === 1 ||
					payload.selectedOption.value === 3
				) {
					newState = {
						...state,
						selectedStatus: filtersOptionList.statusList[0],
						selectedSubstatus: filtersOptionList.subStatusList[0],
						selectedDateType: payload.selectedOption,
					}
				} else if (payload.selectedOption.value === 2) {
					newState = {
						...state,
						selectedStatus: filtersOptionList.statusList[1],
						selectedSubstatus: filtersOptionList.subStatusList[0],
						selectedDateType: payload.selectedOption,
					}
				}
				break
			case 'status':
				newState = {
					...state,
					selectedStatus: payload.selectedOption,
					selectedSubstatus: filtersOptionList.subStatusList[0],
				}
				break
			case 'form':
				if (
					payload.selectedOption.length === 1 &&
					!isAllSelected(payload.selectedOption)
				) {
					newState = { ...state, selectedForm: payload.selectedOption }
				} else {
					let defaultFormOption = []
					defaultFormOption.push(filtersOptionList.processFormsList[0])
					if (isAllSelected(payload.selectedOption)) {
						newState = {
							...state,
							selectedForm: defaultFormOption,
							selectedField: defaultFormOption,
						}
					} else {
						newState = {
							...state,
							selectedForm: payload.selectedOption,
							selectedField: defaultFormOption,
						}
					}
				}
				break
			case 'field':
				if (isAllSelected(payload.selectedOption)) {
					let defaultFormOption = []
					defaultFormOption.push(filtersOptionList.formFieldsList[0])
					newState = { ...state, selectedField: defaultFormOption }
				} else {
					newState = { ...state, selectedField: payload.selectedOption }
				}
				break
			case 'timelineChange':
				newState = {
					...state,
					selectedTimeline: payload.selectedOption,
					selectedStartDate: payload.startDate,
					selectedEndDate: payload.endDate,
				}
				break
			case 'startDateTime':
				if (moment(state.selectedEndDate).isBefore(payload.selectedOption)) {
					newState = {
						...state,
						selectedStartDate: payload.selectedOption,
						selectedEndDate: payload.selectedOption,
					}
				} else if (
					differenceInMonths(state.selectedEndDate, payload.selectedOption) > 5
				) {
					newState = {
						...state,
						selectedStartDate: payload.selectedOption,
						selectedEndDate: addMonths(payload.selectedOption, 6),
					}
				} else {
					newState = { ...state, selectedStartDate: payload.selectedOption }
				}
				break
			case 'endDateTime':
				if (moment(state.selectedStartDate).isAfter(payload.selectedOption)) {
					newState = {
						...state,
						selectedStartDate: payload.selectedOption,
						selectedEndDate: payload.selectedOption,
					}
				} else {
					newState = { ...state, selectedEndDate: payload.selectedOption }
				}
				break
			case 'handleBuildingChange':
				newState = {
					...state,
					selectedBuilding: payload,
					selectedCreator: payload.value === 0 && defaultFilterValue,
				}
				break
			default:
				newState = { ...state }
				break
		}
		return newState
	}

	const [filterdValue, dispatchFilterdValue] = useReducer(
		reducerFilterdValue,
		initialFilterdValue
	)

	useEffect(() => {
		getApplicationList()
	}, [])

	useEffect(() => {
		if (filterList.length > 0) {
			extractStaticOptionsFromFilter(filterList) //Fetch static options
			getReportFilterOptionList(
				buildFetchReportFilterParams(FILTER_TYPE_FETCH.CLUSTER),
				'clusterList',
				'selectedCluster'
			) //Fetch cluster list
			getReportFilterOptionList(
				buildFetchReportFilterParams(FILTER_TYPE_FETCH.SEGMENT),
				'segmentList',
				'selectedSegment'
			) //Fetch segment list
			getReportFilterOptionList(
				buildFetchReportFilterParams(FILTER_TYPE_FETCH.CATEGORY),
				'categoryList',
				'selectedCategory'
			) //Fetch category list
			getReportFilterOptionList(
				buildFetchReportFilterParams(FILTER_TYPE_FETCH.WORKFORCE_TAG),
				'workforceTagList',
				'selectedWorkforce'
			) //Fetch workforce tag
			getReportFilterOptionList(
				buildFetchReportFilterParams(FILTER_TYPE_FETCH.PROCESS),
				'processList',
				'selectedProcess'
			) //Fetch Process list
			getReportFilterOptionList(
				buildFetchReportFilterParams(FILTER_TYPE_FETCH.ASSET_TAG_TYPE1),
				'assetTagType1',
				'selectedTagType1'
			) //Fetch Asset Tag Type 1
			getReportFilterOptionList(
				buildFetchReportFilterParams(FILTER_TYPE_FETCH.ASSET_TAG_TYPE2),
				'assetTagType2',
				'selectedTagType2'
			) //Fetch Asset Tag Type 2
			getReportFilterOptionList(
				buildFetchReportFilterParams(FILTER_TYPE_FETCH.ASSET_TAG_TYPE3),
				'assetTagType3',
				'selectedTagType3'
			) //Fetch Asset Tag Type 3
		}
	}, [filterList])

	useEffect(() => {
		setIsNotValidForm(false)
		setErrMsg('')
	}, [filterdValue])
	useEffect(() => {
		getReportFilterList(filterdValue.selectedSubApplication)
	}, [filterdValue.selectedSubApplication])
	useEffect(() => {
		getReportFilterOptionList(
			buildFetchReportFilterParams(FILTER_TYPE_FETCH.BUILDING),
			'buildingList',
			'selectedBuilding'
		)
	}, [filterdValue.selectedCluster])

	useEffect(() => {
		getReportFilterOptionList(
			buildFetchReportFilterParams(FILTER_TYPE_FETCH.CREATER),
			'creatorList',
			'selectedCreator'
		)
	}, [filterdValue.selectedBuilding])
	useEffect(() => {
		if (filterdValue.selectedCategory !== null) {
			getReportFilterOptionList(
				buildFetchReportFilterParams(FILTER_TYPE_FETCH.PRODUCT_FAMILY),
				'familyList',
				'selectedFamily'
			)
		}
	}, [filterdValue.selectedCategory])
	useEffect(() => {
		if (
			filterdValue.selectedProcess !== null &&
			filterdValue.selectedProcess.length !== 0
		) {
			getFormsFilterOptionList()
		}
	}, [filterdValue.selectedProcess])
	useEffect(() => {
		if (filterdValue.selectedFamily !== null) {
			getReportFilterOptionList(
				buildFetchReportFilterParams(FILTER_TYPE_FETCH.PRODUCT),
				'productList',
				'selectedProduct'
			)
		}
	}, [filterdValue.selectedFamily])
	useEffect(() => {
		if (filterdValue.selectedForm.length > 0) {
			setSelectedFormError(false)
		}
		if (filterdValue.selectedForm.length > 1) {
			dispatchFilterdValue({
				type: 'selectedField',
				payload: [defaultFilterValue],
			})
		}
	}, [filterdValue.selectedForm])
	useEffect(() => {
		if (filterdValue.selectedField.length > 0) {
			setSelectedFieldError(false)
		}
	}, [filterdValue.selectedField])

	useEffect(() => {
		if (filterdValue.selectedTagType1) {
			getReportFilterOptionList(
				buildFetchReportFilterParams(FILTER_TYPE_FETCH.ASSET_TAG1),
				'assetTag1',
				'selectedTag1'
			) //Fetch Asset Tag 1
		}
	}, [filterdValue.selectedTagType1])

	useEffect(() => {
		if (filterdValue.selectedTagType2) {
			getReportFilterOptionList(
				buildFetchReportFilterParams(FILTER_TYPE_FETCH.ASSET_TAG2),
				'assetTag2',
				'selectedTag2'
			) //Fetch Asset Tag 2
		}
	}, [filterdValue.selectedTagType2])

	useEffect(() => {
		if (filterdValue.selectedTagType3) {
			getReportFilterOptionList(
				buildFetchReportFilterParams(FILTER_TYPE_FETCH.ASSET_TAG3),
				'assetTag3',
				'selectedTag3'
			) //Fetch Asset Tag 3
		}
	}, [filterdValue.selectedTagType3])

	const getFormsFilterOptionList = useCallback((search_string = '') => {
		const prevFiltersOptionList = filtersOptionList
		let params = {
			activity_type_id: filterdValue.selectedProcess.value,
			page_limit: 200,
			page_start: 0,
			workforce_id: 0,
			flag: 0,
			search_string: search_string,
			// is_filter: true,
		}
		fetchFormsListMutate(params, {
			onSuccess: async res => {
				const {
					data: { status, response },
				} = res
				if (status === 200) {
					prevFiltersOptionList['processFormsList'] = response
					setFiltersOptionList(prevFiltersOptionList)
					handleFilterChange([defaultFilterValue], 'form')
				}
			},
			onError: async err => {
				console.log(err)
			},
		})
	})
	const extractStaticOptionsFromFilter = useCallback(filterList => {
		const prevFiltersOptionList = filtersOptionList
		filterList.map(listItem => {
			if (!!listItem.filter_inline_data) {
				let optionList = JSON.parse(listItem.filter_inline_data)
				if (!!listItem.tag_type_filter_options) {
					prevFiltersOptionList[listItem.tag_type_filter_options] = optionList
					setFiltersOptionList(prevFiltersOptionList)
				}
				if (listItem.tag_type_mapping_id) {
					dispatchFilterdValue({
						type: 'selectedTimeline',
						payload: optionList[0],
					})
				}
				if (!!listItem.tag_type_filter_name) {
					dispatchFilterdValue({
						type: listItem.tag_type_filter_name,
						payload: optionList[0],
					})
				}
			}
		})
	})
	const assignSipTimeScale = () => {
		let siptimelineList = []
	}
	const validateFilterValues = useCallback(() => {
		// Disabled cases needs to be handled as well.
		if (
			!filterdValue.selectedApplication ||
			filterdValue.selectedApplication.value === -1
		)
			return false
		return filterList.every(filter => {
			switch (filter.filter_id) {
				case FILTER_TYPE_LIST.CLUSTER: //Clusters
					if (
						!filterdValue.selectedCluster ||
						filterdValue.selectedCluster.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.BUILDING: //Geography
					if (
						!filterdValue.selectedBuilding ||
						filterdValue.selectedBuilding.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.RESOURCE: //Creator
					if (
						!filterdValue.selectedCreator ||
						filterdValue.selectedCreator.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.SEGMENT: //Segment
					if (
						!filterdValue.selectedSegment ||
						filterdValue.selectedSegment.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.WORKFORCE: //Workforce Type
					if (
						!filterdValue.selectedWorkforce ||
						filterdValue.selectedWorkforce.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.PRODUCT_CATEGORY: //Product Category
					if (
						!filterdValue.selectedCategory ||
						filterdValue.selectedCategory.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.PRODUCT_FAMILY: //Product Family
					if (
						!filterdValue.selectedFamily ||
						filterdValue.selectedFamily.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.PRODUCT: //Product
					if (
						!filterdValue.selectedProduct ||
						filterdValue.selectedProduct.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.ASSET_TAG_TYPE1: // Asset Tag Type 1
					if (
						!filterdValue.selectedTagType1 ||
						filterdValue.selectedTagType1.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.ASSET_TAG1: // Asset Tag 1
					if (
						!filterdValue.selectedTag1 ||
						filterdValue.selectedTag1.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.ASSET_TAG_TYPE2: // Asset Tag Type 2
					if (
						!filterdValue.selectedTagType2 ||
						filterdValue.selectedTagType2.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.ASSET_TAG2: // Asset Tag 2
					if (
						!filterdValue.selectedTag2 ||
						filterdValue.selectedTag2.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.ASSET_TAG_TYPE3: // Asset Tag Type 3
					if (
						!filterdValue.selectedTagType3 ||
						filterdValue.selectedTagType3.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.ASSET_TAG3: // Asset Tag 3
					if (
						!filterdValue.selectedTag3 ||
						filterdValue.selectedTag3.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.DATE_TYPE: //Date Type
					if (
						filterdValue.selectedSubApplication?.value !== 110 &&
						(!filterdValue.selectedDateType ||
							filterdValue.selectedDateType.value === -1)
					)
						return false
					break
				case FILTER_TYPE_LIST.STATUS: //Status
					if (
						filterdValue.selectedSubApplication?.value !== 110 &&
						filterdValue.selectedDateType?.value !== 2 &&
						(!filterdValue.selectedStatus ||
							filterdValue.selectedStatus.value === -1)
					)
						return false
					break
				case FILTER_TYPE_LIST.SUB_STATUS: //Sub Status
					if (
						filterdValue.selectedSubApplication.value === 111 &&
						filterdValue.selectedStatus.value === 1 &&
						(!filterdValue.selectedSubstatus ||
							filterdValue.selectedSubstatus.value === -1)
					)
						return false
					break
				case FILTER_TYPE_LIST.TIMELINE: //Timeline
					if (
						!filterdValue.selectedTimeline ||
						filterdValue.selectedTimeline.value === -1
					)
						return false
					break
				case FILTER_TYPE_LIST.PROCESS: //Process
					if (
						!filterdValue.selectedProcess ||
						filterdValue.selectedProcess.value === -1
					)
						return false
					break
				default:
					break
			}
			return true
		})
	})

	const getReportFilterList = useCallback((tagType = null) => {
		let params = {
			tag_type_id: !!tagType ? tagType.value : 0,
			is_export: 1,
		}
		fetchReportFilterListMutate(params, {
			onSuccess: async res => {
				const {
					data: { status, response },
				} = res
				if (status === 200) {
					setFilterList(response)
				}
			},
			onError: async err => {
				console.log(err)
			},
		})
	})

	const getApplicationList = useCallback((filterList = 'applicationList') => {
		fetchSubApplicationListMutate(
			{
				...buildFetchReportFilterParams(FILTER_TYPE_FETCH.SUBAPPLICATION),
				access_level_id: 27,
			},
			{
				onSuccess: async res => {
					const {
						data: { status, response },
					} = res
					if (status === 200) {
						setApplicationList(response)
						dispatchFilterdValue({
							type: 'selectedApplication',
							payload: response[0],
						})
						getReportFilterOptionList(
							buildFetchReportFilterParams(FILTER_TYPE_FETCH.SUBAPPLICATION),
							'subApplicationList',
							'selectedSubApplication'
						)
					}
				},
				onError: async err => {
					console.log(err)
				},
			}
		)
	})
	const getReportFilterOptionList = useCallback(
		(payload, filterList, selectedFilter) => {
			const { access_level_id } = payload
			const prevFiltersOptionList = filtersOptionList
			switch (access_level_id) {
				case FILTER_TYPE_FETCH.SUBAPPLICATION:
					fetchSubApplicationListMutate(payload, {
						onSuccess: async res => {
							const {
								data: { status, response },
							} = res
							if (status === 200) {
								prevFiltersOptionList[filterList] = response
								setFiltersOptionList(prevFiltersOptionList)
								dispatchFilterdValue({
									type: selectedFilter,
									payload: response[0],
								})
							}
						},
						onError: async err => {
							console.log(err)
						},
					})
					break
				case FILTER_TYPE_FETCH.CLUSTER:
					fetchClusterListMutate(payload, {
						onSuccess: async res => {
							const {
								data: { status, response },
							} = res
							if (status === 200) {
								prevFiltersOptionList[filterList] = response
								setFiltersOptionList(prevFiltersOptionList)
								dispatchFilterdValue({
									type: selectedFilter,
									payload: response[0],
								})
							}
						},
						onError: async err => {
							console.log(err)
						},
					})
					break
				case FILTER_TYPE_FETCH.SEGMENT:
					fetchSegmentListMutate(payload, {
						onSuccess: async res => {
							const {
								data: { status, response },
							} = res
							if (status === 200) {
								prevFiltersOptionList[filterList] = response
								setFiltersOptionList(prevFiltersOptionList)
								dispatchFilterdValue({
									type: selectedFilter,
									payload: response[0],
								})
							}
						},
						onError: async err => {
							console.log(err)
						},
					})
					break
				case FILTER_TYPE_FETCH.CREATER:
					fetchCreaterListMutate(payload, {
						onSuccess: async res => {
							const {
								data: { status, response },
							} = res
							if (status === 200) {
								prevFiltersOptionList[filterList] = response
								setFiltersOptionList(prevFiltersOptionList)
								dispatchFilterdValue({
									type: selectedFilter,
									payload: response[0],
								})
							}
						},
						onError: async err => {
							console.log(err)
						},
					})
					break
				case FILTER_TYPE_FETCH.PROCESS:
					fetchProcessListMutate(payload, {
						onSuccess: async res => {
							const {
								data: { status, response },
							} = res
							if (status === 200) {
								prevFiltersOptionList[filterList] = response
								setFiltersOptionList(prevFiltersOptionList)
								dispatchFilterdValue({
									type: selectedFilter,
									payload: response[0],
								})
							}
						},
						onError: async err => {
							console.log(err)
						},
					})
					break
				case FILTER_TYPE_FETCH.WORKFORCE_TAG:
					fetchWorkforceListMutate(payload, {
						onSuccess: async res => {
							const {
								data: { status, response },
							} = res
							if (status === 200) {
								prevFiltersOptionList[filterList] = response
								setFiltersOptionList(prevFiltersOptionList)
								dispatchFilterdValue({
									type: selectedFilter,
									payload: response[0],
								})
							}
						},
						onError: async err => {
							console.log(err)
						},
					})
					break
				case FILTER_TYPE_FETCH.ASSET_TAG_TYPE1:
					fetchAssetTagType1Mutate(payload, {
						onSuccess: async res => {
							const {
								data: { status, response },
							} = res
							if (status === 200) {
								prevFiltersOptionList[filterList] = response
								setFiltersOptionList(prevFiltersOptionList)
								dispatchFilterdValue({
									type: selectedFilter,
									payload: response[0],
								})
							}
						},
						onError: async err => {
							console.log(err)
						},
					})
					break
				case FILTER_TYPE_FETCH.ASSET_TAG_TYPE2:
					fetchAssetTagType2Mutate(payload, {
						onSuccess: async res => {
							const {
								data: { status, response },
							} = res
							if (status === 200) {
								prevFiltersOptionList[filterList] = response
								setFiltersOptionList(prevFiltersOptionList)
								dispatchFilterdValue({
									type: selectedFilter,
									payload: response[0],
								})
							}
						},
						onError: async err => {
							console.log(err)
						},
					})
					break
				case FILTER_TYPE_FETCH.ASSET_TAG_TYPE3:
					fetchAssetTagType3Mutate(payload, {
						onSuccess: async res => {
							const {
								data: { status, response },
							} = res
							if (status === 200) {
								prevFiltersOptionList[filterList] = response
								setFiltersOptionList(prevFiltersOptionList)
								dispatchFilterdValue({
									type: selectedFilter,
									payload: response[0],
								})
							}
						},
						onError: async err => {
							console.log(err)
						},
					})
					break
				case FILTER_TYPE_FETCH.ASSET_TAG1:
					fetchAssetTag1Mutate(payload, {
						onSuccess: async res => {
							const {
								data: { status, response },
							} = res
							if (status === 200) {
								prevFiltersOptionList[filterList] = response
								setFiltersOptionList(prevFiltersOptionList)
								dispatchFilterdValue({
									type: selectedFilter,
									payload: response[0],
								})
							}
						},
						onError: async err => {
							console.log(err)
						},
					})
					break
				case FILTER_TYPE_FETCH.ASSET_TAG2:
					fetchAssetTag2Mutate(payload, {
						onSuccess: async res => {
							const {
								data: { status, response },
							} = res
							if (status === 200) {
								prevFiltersOptionList[filterList] = response
								setFiltersOptionList(prevFiltersOptionList)
								dispatchFilterdValue({
									type: selectedFilter,
									payload: response[0],
								})
							}
						},
						onError: async err => {
							console.log(err)
						},
					})
					break
				case FILTER_TYPE_FETCH.ASSET_TAG3:
					fetchAssetTag3Mutate(payload, {
						onSuccess: async res => {
							const {
								data: { status, response },
							} = res
							if (status === 200) {
								prevFiltersOptionList[filterList] = response
								setFiltersOptionList(prevFiltersOptionList)
								dispatchFilterdValue({
									type: selectedFilter,
									payload: response[0],
								})
							}
						},
						onError: async err => {
							console.log(err)
						},
					})
					break
				case FILTER_TYPE_FETCH.CATEGORY:
					fectchProcuctCategoryListMutate(payload, {
						onSuccess: async res => {
							const {
								data: { status, response },
							} = res
							if (status === 200) {
								prevFiltersOptionList[filterList] = response
								setFiltersOptionList(prevFiltersOptionList)
								dispatchFilterdValue({
									type: selectedFilter,
									payload: response[0],
								})
							}
						},
						onError: async err => {
							console.log(err)
						},
					})
					break
				default:
					fetchReportFilterValueMutate({
						...payload,
						filterList,
						selectedFilter,
					})
			}
		}
	)
	const manageDateKeyboardSpin = e => {
		e.preventDefault()
	}

	const buildFetchReportFilterParams = (flag, searchStr = '') => {
		return {
			access_level_id: flag,
			tag_type_id:
				flag === FILTER_TYPE_FETCH.ASSET_TAG1
					? filterdValue.selectedTagType1.value
					: flag === FILTER_TYPE_FETCH.ASSET_TAG2
					? filterdValue.selectedTagType2.value
					: flag === FILTER_TYPE_FETCH.ASSET_TAG3
					? filterdValue.selectedTagType3.value
					: flag === FILTER_TYPE_FETCH.PROCESS &&
					  filterdValue.selectedSubApplication
					? filterdValue.selectedSubApplication.value
					: 0,
			tag_id:
				flag === FILTER_TYPE_FETCH.BUILDING && filterdValue.selectedCluster
					? filterdValue.selectedCluster.value
					: flag === FILTER_TYPE_FETCH.PRODUCT_FAMILY &&
					  filterdValue.selectedCategory
					? filterdValue.selectedCategory.value
					: 0,
			activity_type_id:
				flag === FILTER_TYPE_FETCH.PRODUCT && filterdValue.selectedFamily
					? filterdValue.selectedFamily.value
					: 0,
			target_account_id:
				flag === FILTER_TYPE_FETCH.CREATER && filterdValue.selectedBuilding
					? filterdValue.selectedBuilding.value
					: 0,
			search_string: searchStr,
			is_export: 1,
			page_start: 0,
			page_limit: 500,
			flag_report_type: 9,
		}
	}

	//Fetch fields from form
	const getFormFieldList = selectedOption => {
		const prevFiltersOptionList = filtersOptionList
		let params = {
			form_id: selectedOption[0].value,
			start_from: 0,
			limit_value: 200,
			is_filter: true,
		}
		fetchFormsFieldListMutate(params, {
			onSuccess: async res => {
				const {
					data: { status, response },
				} = res
				if (status === 200) {
					prevFiltersOptionList['formFieldsList'] = response
					setFiltersOptionList(prevFiltersOptionList)
				}
			},
			onError: async err => {
				console.log(err)
			},
		})
	}
	const handleFilterChange = (selectedOption, type) => {
		if (type === 'role') {
			dispatchFilterdValue({ type: 'selectedRole', payload: selectedOption })
		}
		if (type === 'dateType') {
			dispatchFilterdValue({
				type: 'dateType',
				payload: { selectedOption: selectedOption },
			})
		}
		if (type === 'status') {
			dispatchFilterdValue({
				type: 'status',
				payload: { selectedOption: selectedOption },
			})
		}
		if (type === 'substatus') {
			dispatchFilterdValue({
				type: 'selectedSubstatus',
				payload: selectedOption,
			})
		}
		if (type === 'process') {
			dispatchFilterdValue({ type: 'selectedProcess', payload: selectedOption })
		}
		if (type === 'form') {
			dispatchFilterdValue({
				type: 'form',
				payload: { selectedOption: selectedOption },
			})
			if (selectedOption.length === 1 && !isAllSelected(selectedOption)) {
				getFormFieldList(selectedOption)
			}
		}
		if (type === 'field') {
			dispatchFilterdValue({
				type: 'field',
				payload: { selectedOption: selectedOption },
			})
		}
		if (type === 'reciever') {
			dispatchFilterdValue({
				type: 'selectedRecievers',
				payload: selectedOption.target.value,
			})
		}
	}
	const handleTimeLineChange = selectedOption => {
		let startDate, endDate
		let today = new Date()
		startDate = startOfDay(today)
		endDate = endOfDay(today)
		if (selectedOption.value !== 0) {
			//Day
			if (selectedOption.value === 1) {
				startDate = startOfDay(today)
				endDate = endOfDay(today)
			}
			//Week
			if (selectedOption.value === 2) {
				startDate = startOfWeek(today, { weekStartsOn: 1 })
				endDate = endOfWeek(today, { weekStartsOn: 1 })
			}
			//Month
			if (selectedOption.value === 3) {
				startDate = startOfMonth(today)
				endDate = endOfDay(today)
			}
			//Last Month
			if (selectedOption.value === 4) {
				startDate = startOfMonth(subMonths(today, 1))
				endDate = endOfMonth(startDate)
				// endDate = subMonths(endDate, 1);
			}
			//Next Month
			if (selectedOption.value === 5) {
				startDate = startOfMonth(addMonths(today, 1))
				endDate = endOfMonth(startDate, 1)
			}
			//Last Quater
			if (selectedOption.value === 6) {
				startDate = startOfQuarter(subQuarters(today, 1))
				endDate = endOfQuarter(startDate)
			}
			//Current Quater
			if (selectedOption.value === 10) {
				startDate = startOfQuarter(today)
				endDate = endOfQuarter(today)
			}
			//Next Quater
			if (selectedOption.value === 7) {
				startDate = startOfQuarter(addQuarters(today, 1))
				endDate = endOfQuarter(startDate)
			}
			//Previous 6 Months
			if (selectedOption.value === 8) {
				startDate = startOfMonth(subMonths(today, 6))
				endDate = endOfDay(today)
			}
			//Next 6 Months
			if (selectedOption.value === 9) {
				startDate = endOfDay(today)
				endDate = endOfMonth(addMonths(today, 6))
			}
		}
		dispatchFilterdValue({
			type: 'timelineChange',
			payload: {
				selectedOption,
				startDate,
				endDate,
			},
		})
	}

	const assignFilterDates = (selectedOption, filterName) => {
		if (filterName === 'startDateTime') {
			dispatchFilterdValue({
				type: 'startDateTime',
				payload: {
					selectedOption,
				},
			})
		} else if (filterName === 'endDateTime') {
			dispatchFilterdValue({
				type: 'endDateTime',
				payload: {
					selectedOption,
				},
			})
		} else {
			dispatchFilterdValue({
				type: 'selectedEndDate',
				selectedOption,
			})
		}
	}

	const onSaveExport = () => {
		if (!filterdValue.selectedForm.length) {
			setSelectedFormError(true)
		} else if (!filterdValue.selectedField.length) {
			setSelectedFieldError(true)
		} else {
			setSelectedFieldError(false)
			setSelectedFormError(false)
			// setIsSaveStart(true);
			if (exportName === '' && !!isSaveStart) {
				setIsNotValidForm(true)
				setErrMsg('Please Add Name to Save the Filter Setting')
			} else if (validateFilterValues()) {
				// eventLabelTrack({
				// 	categoryId: 3,
				// 	actionId: 1,
				// 	label: locale['Filters saved for data export'],
				// })

				cbSaveExport({ ...filterdValue, exportName })
			} else {
				setIsNotValidForm(true)
				setErrMsg('Filter values cannot be blank')
			}
		}
	}
	const onSaveStart = () => {
		if (!filterdValue.selectedForm.length) {
			setSelectedFormError(true)
		} else if (!filterdValue.selectedField.length) {
			setSelectedFieldError(true)
		} else {
			setSelectedFieldError(false)
			setSelectedFormError(false)
			setIsSaveStart(true)
		}
	}

	const onSubmitExport = () => {
		if (!filterdValue.selectedForm.length) {
			setSelectedFormError(true)
		} else if (!filterdValue.selectedField.length) {
			setSelectedFieldError(true)
		} else {
			setSelectedFieldError(false)
			setSelectedFormError(false)
			if (validateFilterValues()) {
				// eventLabelTrack({
				// 	categoryId: 3,
				// 	actionId: 0,
				// 	label: locale['Data exported with filters'],
				// })
				cbAddExport(filterdValue)
			} else {
				setIsNotValidForm(true)
				setErrMsg('Filter values cannot be blank')
			}
		}
	}
	const getField = listItem => {
		switch (listItem.filter_id) {
			case FILTER_TYPE_LIST.CLUSTER: //Clusters
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>

						<Select
							menuPlacement={'auto'}
							placeholder={locale['Select Cluster']}
							options={filtersOptionList.clusterList}
							name='clusters'
							value={filterdValue.selectedCluster}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedCluster', payload: e })
							}}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.BUILDING: //Geography
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={locale['Select Building']}
							name='building'
							value={filterdValue.selectedBuilding}
							onChange={e => {
								dispatchFilterdValue({
									type: 'handleBuildingChange',
									payload: e,
								})
								// handleFilterChange(e, 'building');
							}}
							options={filtersOptionList.buildingList}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.RESOURCE: //Creator
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							name='creator'
							value={filterdValue.selectedCreator}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedCreator', payload: e })
								// handleFilterChange(e, 'creator');
							}}
							options={filtersOptionList.creatorList}
							isDisabled={
								!!filterdValue.selectedBuilding &&
								filterdValue.selectedBuilding.value === 0
							}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.SEGMENT: //Segment
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							isSearchable={false}
							value={filterdValue.selectedSegment}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedSegment', payload: e })
								// handleFilterChange(e, 'segment');
							}}
							options={filtersOptionList.segmentList}
							isDisabled={
								filterdValue.selectedSubApplication &&
								filterdValue.selectedSubApplication.value === 110
							}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.WORKFORCE: //Workforce Type
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							isSearchable={false}
							name='workforce_tag'
							value={filterdValue.selectedWorkforce}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedWorkforce', payload: e })
								// handleFilterChange(e, 'workforce_tag');
							}}
							options={filtersOptionList.workforceTagList}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.PRODUCT_CATEGORY: //Product Category
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							isSearchable={false}
							name='category'
							value={filterdValue.selectedCategory}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedCategory', payload: e })
								// handleFilterChange(e, 'category');
							}}
							options={filtersOptionList.categoryList}
							isDisabled={
								!!filterdValue.selectedSubApplication &&
								filterdValue.selectedSubApplication.value !== 111
							}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.PRODUCT_FAMILY: //Product Family
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							isSearchable={false}
							name='family'
							value={filterdValue.selectedFamily}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedFamily', payload: e })
								// handleFilterChange(e, 'family');
							}}
							options={filtersOptionList.familyList}
							isDisabled={
								(!!filterdValue.selectedSubApplication &&
									filterdValue.selectedSubApplication.value !== 111) ||
								(!!filterdValue.selectedCategory &&
									(filterdValue.selectedCategory.value === -1 ||
										filterdValue.selectedCategory.value === 0))
							}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.PRODUCT: //Product
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							value={filterdValue.selectedProduct}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedProduct', payload: e })
								// handleFilterChange(e, 'product');
							}}
							// onKeyDown={(e) => {
							//     onGetReportFilterValue(
							//     buildReportFilterParams(
							//         24,
							//         e.target.value
							//     )
							//     );
							// }}
							options={filtersOptionList.productList}
							isDisabled={
								(!!filterdValue.selectedSubApplication &&
									filterdValue.selectedSubApplication.value !== 111) ||
								filterdValue.selectedFamily === null ||
								(filterdValue.selectedFamily &&
									filterdValue.selectedFamily.value === 0)
							}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.ASSET_TAG_TYPE1: // Asset Tag Type 1
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							name='asset tag type 1'
							value={filterdValue.selectedTagType1}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedTagType1', payload: e })
							}}
							options={filtersOptionList.assetTagType1}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.ASSET_TAG1: // Asset Tag 1
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							name='asset tag 1'
							value={filterdValue.selectedTag1}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedTag1', payload: e })
							}}
							options={filtersOptionList.assetTag1}
							isDisabled={
								!!filterdValue.selectedTagType1 &&
								filterdValue.selectedTagType1.value === 0
							}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.ASSET_TAG_TYPE2: // Asset Tag Type 2
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							name='asset tag type 2'
							value={filterdValue.selectedTagType2}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedTagType2', payload: e })
							}}
							options={filtersOptionList.assetTagType2}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.ASSET_TAG2: // Asset Tag 2
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							name='asset tag 2'
							value={filterdValue.selectedTag2}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedTag2', payload: e })
							}}
							options={filtersOptionList.assetTag2}
							isDisabled={
								!!filterdValue.selectedTagType2 &&
								filterdValue.selectedTagType2.value === 0
							}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.ASSET_TAG_TYPE3: // Asset Tag Type 3
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							name='asset tag type 3'
							value={filterdValue.selectedTagType3}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedTagType3', payload: e })
							}}
							options={filtersOptionList.assetTagType3}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.ASSET_TAG3: // Asset Tag 3
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							name='asset tag 3'
							value={filterdValue.selectedTag3}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedTag3', payload: e })
							}}
							options={filtersOptionList.assetTag3}
							isDisabled={
								!!filterdValue.selectedTagType3 &&
								filterdValue.selectedTagType3.value === 0
							}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.DATE_TYPE: //Date Type
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							isSearchable={false}
							value={filterdValue.selectedDateType}
							onChange={e => {
								handleFilterChange(e, 'dateType')
							}}
							options={filtersOptionList.dateTypeList}
							isDisabled={
								!!filterdValue.selectedSubApplication &&
								filterdValue.selectedSubApplication.value === 110
							}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.ROLE: //Status
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							isSearchable={false}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							value={filterdValue.selectedRole}
							onChange={e => {
								handleFilterChange(e, 'role')
							}}
							options={filtersOptionList.roleList}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.STATUS: //Status
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							isSearchable={false}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							value={filterdValue.selectedStatus}
							onChange={e => {
								handleFilterChange(e, 'status')
							}}
							options={filtersOptionList.statusList}
							isDisabled={
								(!!filterdValue.selectedSubApplication &&
									filterdValue.selectedSubApplication.value === 110) ||
								(filterdValue.selectedDateType !== null &&
									filterdValue.selectedDateType.value === 2)
							}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.SUB_STATUS: //Sub Status
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							isSearchable={false}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							value={filterdValue.selectedSubstatus}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedSubstatus', payload: e })
								// handleFilterChange(e, 'substatus');
							}}
							options={filtersOptionList.subStatusList}
							isDisabled={
								(!!filterdValue.selectedSubApplication &&
									filterdValue.selectedSubApplication.value !== 111) ||
								(filterdValue.selectedStatus !== null &&
									filterdValue.selectedStatus.value !== 1)
							}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.START_DATE: //Start Date
				let isStartDateDisabled =
					filterdValue.selectedTimeline !== null &&
					filterdValue.selectedTimeline.value !== 0
						? true
						: false
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<DatePicker
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							dateFormat='dd/MM/yyyy'
							onChangeRaw={manageDateKeyboardSpin}
							className={isStartDateDisabled ? 'date-css disabled' : 'date-css'}
							selected={filterdValue.selectedStartDate}
							onChange={e => assignFilterDates(e, 'startDateTime')}
							required
							minDate={subMonths(new Date(), 6)}
							maxDate={addMonths(new Date(), 6)}
							disabled={isStartDateDisabled}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.END_DATE: //End Date
				let isEndDateDisabled =
					filterdValue.selectedTimeline !== null &&
					filterdValue.selectedTimeline.value !== 0
						? true
						: false
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<DatePicker
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							dateFormat='dd/MM/yyyy'
							onChangeRaw={manageDateKeyboardSpin}
							className={isEndDateDisabled ? 'date-css disabled' : 'date-css'}
							selected={filterdValue.selectedEndDate}
							onChange={e => assignFilterDates(e, 'endDateTime')}
							required
							minDate={subMonths(new Date(), 6)}
							maxDate={addMonths(filterdValue.selectedStartDate, 6)}
							disabled={isEndDateDisabled}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.FORMS: //Forms
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							isMulti
							closeMenuOnSelect={false}
							menuPlacement={'top'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							value={filterdValue.selectedForm}
							onChange={e => {
								setSelectedFormError(false)
								handleFilterChange(e, 'form')
							}}
							options={
								!!filterdValue.selectedForm &&
								isAllSelected(filterdValue.selectedForm)
									? []
									: filtersOptionList.processFormsList
							}
							isDisabled={
								!filterdValue.selectedProcess ||
								filterdValue.selectedProcess.value === -1
							}
						/>
						{selectedFormError && (
							<Text p={'2'} color={'red.400'}>
								{`${locale['Please select']} ${listItem.tag_type_filter_label}`}
							</Text>
						)}
					</Box>
				)
			case FILTER_TYPE_LIST.FIELDS: //Fields
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'top'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							isMulti
							closeMenuOnSelect={false}
							value={filterdValue.selectedField}
							onChange={e => {
								setSelectedFieldError(false)
								handleFilterChange(e, 'field')
							}}
							options={
								!!filterdValue.selectedField &&
								isAllSelected(filterdValue.selectedField)
									? []
									: filtersOptionList.formFieldsList
							}
							isDisabled={
								!filterdValue.selectedForm ||
								!filterdValue.selectedForm.length ||
								isAllSelected(filterdValue.selectedForm) ||
								filterdValue.selectedForm.length > 1
							}
						/>
						{selectedFieldError && (
							<Text p={'2'} color={'red.400'}>
								{`${locale['Please select']} ${listItem.tag_type_filter_label}`}
							</Text>
						)}
					</Box>
				)
			case FILTER_TYPE_LIST.TIMELINE: //Timeline
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							menuPlacement={'auto'}
							placeholder={`${locale['Select']} ${listItem.tag_type_filter_label}`}
							isSearchable={false}
							name='timeline'
							value={filterdValue.selectedTimeline}
							onChange={e => {
								handleTimeLineChange(e)
							}}
							options={filtersOptionList.timelineList}
						/>
					</Box>
				)
			case FILTER_TYPE_LIST.PROCESS: //Process
				return (
					<Box key={Math.random() * Date.now()}>
						<Text>{listItem.tag_type_filter_label}</Text>
						<Select
							placeholder={`Select ${listItem.tag_type_filter_label}`}
							isSearchable={false}
							name='processes'
							value={filterdValue.selectedProcess}
							onChange={e => {
								dispatchFilterdValue({ type: 'selectedProcess', payload: e })
							}}
							options={filtersOptionList.processList}
						/>
					</Box>
				)

			default:
				break
		}
	}

	useEffect(() => {
		if (filterdValue.selectedApplication?.value) {
			getReportFilterOptionList(
				{
					...buildFetchReportFilterParams(FILTER_TYPE_FETCH.SUBAPPLICATION),
					application_id: filterdValue.selectedApplication?.value,
				},
				'subApplicationList',
				'selectedSubApplication'
			)
		}
	}, [filterdValue.selectedApplication])

	return (
		<>
			<VStack
				align='stretch'
				spacing={3}
				maxH='calc(100vh - 225px)'
				overflowY='scroll'
				pr='4px'
			>
				<Box key={Math.random() * Date.now()}>
					<Text>{locale['Application']}</Text>
					<Select
						placeholder={locale['Select Application']}
						options={applicationList}
						name='application'
						value={filterdValue.selectedApplication}
						onChange={e => {
							dispatchFilterdValue({ type: 'selectedApplication', payload: e })
						}}
					/>
				</Box>
				<Text>{locale['Subapplication']}</Text>
				<Select
					placeholder={locale['Select SubApplication']}
					options={filtersOptionList.subApplicationList}
					name='sub-application'
					value={filterdValue.selectedSubApplication}
					onChange={e => {
						dispatchFilterdValue({ type: 'selectedSubApplication', payload: e })
					}}
				/>
				{!isLoading ? (
					filterList.length > 0 ? (
						filterList.map((listItem, i) => {
							return getField(listItem)
						})
					) : (
						<Text>{locale['No Access']}</Text>
					)
				) : (
					[1, 2, 3, 4, 5, 6].map(i => {
						return (
							<Skeleton
								boxShadow='md'
								borderRadius='md'
								key={Math.random() * Date.now()}
								p={2}
								w='100%'
								h='50px'
								mb={1}
							/>
						)
					})
				)}
				{isSaveStart ? (
					<Box key={Math.random() * Date.now()}>
						<Text>{locale['Filter Name']}</Text>
						<Input
							placeholder='Please Enter Name'
							size='md'
							required
							autoFocus
							onChange={e => setExportName(e.target.value)}
							value={exportName}
						/>
						{/* isNotValidForm */}
					</Box>
				) : null}
			</VStack>

			<HStack my={2} justifyContent='space-around' w='full' pt='10px'>
				<Button
					colorScheme={localStorage.getItem('color')}
					variant='outline'
					minW='140px'
					size='sm'
					borderRadius='sm'
					onClick={() => {
						// sectionDetailedTrack({
						// 	category: GA_CATEGORY_EXPORT,
						// 	action: 'Export Form',
						// 	label: locale['Submit Export'],
						// })
						// dataLayerTagManager('button_click', {
						// 	viewName: 'Export',
						// 	buttonName: isSaveStart ? 'Cancel' : 'Apply',
						// })
						isSaveStart ? setIsSaveStart(false) : onSubmitExport()
					}}
				>
					{isSaveStart ? locale['Cancel'] : locale['Apply']}
				</Button>
				<Button
					colorScheme={localStorage.getItem('color')}
					bg={localStorage.getItem('color')}
					variant='solid'
					minW='140px'
					size='sm'
					borderRadius='sm'
					onClick={() => {
						// sectionDetailedTrack({
						// 	category: GA_CATEGORY_EXPORT,
						// 	action: 'Export Form',
						// 	label: locale['Save Export'],
						// })
						// dataLayerTagManager('button_click', {
						// 	viewName: 'Export',
						// 	buttonName: isSaveStart ? 'Submit' : 'Save',
						// })
						isSaveStart ? onSaveExport() : onSaveStart()
					}}
				>
					{isSaveStart ? locale['Submit'] : locale['Save']}
				</Button>
			</HStack>
		</>
	)
}
export default AddExportForm
