/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import {
	useDisclosure,
	Box,
	IconButton,
	Icon,
	Tag,
	Spacer,
	Flex,
	Text,
	HStack,
	VStack,
	Input,
	Textarea,
	FormControl,
	FormLabel,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	DrawerHeader,
	Divider,
	Button,
	DrawerFooter,
} from '@chakra-ui/react'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'
import { BiDownload } from 'react-icons/bi'
import { BiMailSend } from 'react-icons/bi'
import {
	formatDDMMYYYYHHMM,
	getFormatDDMMYYYY,
} from '../../../utils/date.utils'
import { useSendEmail } from '../../../hooks/form.hooks'
import { Collapse } from '@chakra-ui/transition'
import Card from '../../../components/card'
import { downloadAwsFile } from '../../../utils/aws.util'
import { useEffect } from 'react'
import { useAuthContext } from '../../../context/auth.context'
import { useLanguageContext } from '../../../context/locale.context'
import { AlertBox } from '../../../components/AlertBox'
import { validateEmailUsernamePassword } from '../../../utils/common.util'
import { customEmailTemplate } from '../../../utils/email-templates.utils'
import { sectionDetailedTrack } from '../../repository/repo.utils'
import { GA_CATEGORY_DIALOG_BOX } from './../../repository/repo.utils'

const ExportListItem = ({ row }) => {
	let inlineReportData = JSON.parse(row.report_inline_data)
	const processName = inlineReportData.process_name
	const formName = inlineReportData.form_names
	const isDownload =
		(row.report_status_id === 3 ||
			row.report_status_id === 5 ||
			row.report_status_id === 6) &&
		!!row.data_entity_text_1
			? true
			: false
	const downloadTagColor = isDownload ? 'transparent' : 'gray.100'
	const downloadTextColor = isDownload
		? localStorage.getItem('color')
		: 'black.500'
	const { mutate: mutateSendEmail } = useSendEmail()
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const [sendEmail, setSendEmail] = useState(false)
	const [email, setEmail] = useState('')
	const [receiverName, setReceiverName] = useState('')
	const [senderName, setSenderName] = useState('')
	const [subject, setSubject] = useState('')
	const [message, setMessage] = useState('')
	const [error, setError] = useState('')
	const [username, setUsername] = useState('')
	const [alertMessage, setAlertMessage] = useState('')
	const [attachmentFileUrl, setAttachmentFileUrl] = useState('')
	const { isOpen, onToggle } = useDisclosure()
	const { isOpen: isAlertOpen, onOpen, onClose: alertClose } = useDisclosure()
	const {
		isOpen: isSendEmail,
		onOpen: onOpenEmail,
		onClose: onCloseSendEmail,
	} = useDisclosure()

	const onOpenSendEmail = s3Url => {
		setSendEmail(true)
		setAttachmentFileUrl(s3Url)
		onOpenEmail()
	}

	useEffect(() => {
		setSenderName(authData.operating_asset_first_name)
		if (
			!!authData.operating_asset_username &&
			!!authData.asset_email_password
		) {
			setUsername(authData.operating_asset_username)
		} else {
			setUsername('')
		}
	}, [])

	const validateSendMail = payload => {
		if (payload.outlook_email_integration === 1) {
			const { valid, msg } = validateEmailUsernamePassword(authData)
			if (valid) return true
			else {
				setAlertMessage(msg)
				onOpen()
				return false
			}
		} else if (payload.outlook_email_integration === 0) {
			return true
		}
		return false
	}

	const sendEmailv8 = payload => {
		mutateSendEmail(
			{
				payload,
			},
			{
				onSuccess: async data => {
					if (data.status === 200) {
						setAlertMessage('E-mail sent successfully!')
						onOpen()
						resetForm()
						// onClose();
					} else {
						setAlertMessage(data.response[0].message)
						onOpen()
						resetForm()
					}
				},
			}
		)
	}

	const sendMailParams = async () => {
		let emails
		if (email) {
			emails = email.split(',')
		}
		return {
			asset_id: authData.asset_id,
			organization_id: authData.organization_id,
			asset_token_auth: authData.asset_token_auth,
			customer_name: receiverName,
			email_subject: subject,
			email_body: message,
			email_receivers: emails,
			email_sender_name: 'GreneOS',
			outlook_email_integration: 0,
			email_sender: 'support@greneos.com',
			flag: 0,
			email_sender_username: username,
			email_sender_asset_id: authData.asset_id,
			html_template: btoa(
				customEmailTemplate({
					org_image: authData.organization_image_path || null,
					org_id: authData.organization_id,
					org_name: authData.organization_name,
					senderName: 'GreneOS',
					recieverName: receiverName,
					formsForReview: [],
					reviewUrl: '',
					message: message,
					formsForUpdate: [],
				})
			),
			s3_url_attachment: attachmentFileUrl,
			with_size_validation: 1,
		}
	}
	const resetForm = () => {
		// setMappedForms([]);
		setEmail('')
		setReceiverName('')
		setSenderName(authData.operating_asset_first_name || '')
		//setUpdateForms([]);
		//setReviewForms([]);
		setSubject('')
		setMessage('')
	}

	const customClose = () => {
		alertClose()
		onCloseSendEmail()
		// loadAllForms();
	}
	const onSendMail = async () => {
		const { operating_asset_first_name } = authData
		if (
			!email ||
			!senderName ||
			!receiverName ||
			// !selectedReviewForms ||
			// !selectedUpdateForms ||
			!subject ||
			!message
			//!Object.keys(selectedReviewForms).length
		) {
			setError('All Fields are mandatory!')
		} else {
			setError('')
			const payload = await sendMailParams()

			if (validateSendMail(payload)) {
				sendEmailv8(payload)
			}
		}
	}
	return (
		<>
			{!!sendEmail ? (
				<Drawer
					className={`form-add-onfly-drawer`}
					isOpen={isSendEmail}
					placement='right'
					onClose={onCloseSendEmail}
					size='md'
				>
					<DrawerOverlay className={`form-add-onfly-drawer-overlay`} />
					<DrawerContent className={`form-add-onfly-drawer-content`}>
						<DrawerHeader fontSize='lg' fontWeight='900'>
							{locale['Compose E-Mail']}
						</DrawerHeader>
						<Divider />
						<DrawerCloseButton
							className={`form-add-onfly-drawer-close-button`}
						/>
						<DrawerBody className={`form-add-onfly-drawer-body`}>
							<VStack my={5}>
								<FormControl isRequired>
									<FormLabel fontWeight='400' fontSize='md' my={1}>
										{locale['Receiver email']}
									</FormLabel>
									<Input
										type='email'
										placeholder={locale['Enter email']}
										value={email}
										onChange={e => setEmail(e.target.value)}
									/>
									<Text color='gray'>
										{
											locale[
												'Commas can be used to separate multiple recipients'
											]
										}{' '}
									</Text>
								</FormControl>
								<FormControl>
									<FormLabel fontWeight='400' fontSize='md' my={1}>
										{locale['Reciever Name']}
									</FormLabel>
									<Input
										placeholder={locale['Enter Receiver Name']}
										value={receiverName}
										onChange={e => setReceiverName(e.target.value)}
									/>
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight='400' fontSize='md' my={1}>
										{locale['Sender Name']}
									</FormLabel>
									<Input
										placeholder={locale['Enter Sender Name']}
										value={'GreneOS'}
										onChange={e => setSenderName(e.target.value)}
										isDisabled={true}
									/>
								</FormControl>

								<FormControl isRequired>
									<FormLabel fontWeight='400' fontSize='md' my={1}>
										{locale['Add Subject Line']}
									</FormLabel>
									<Input
										placeholder={locale['Add Subject Line']}
										value={subject}
										onChange={e => setSubject(e.target.value)}
									/>
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight='400' fontSize='md' my={1}>
										{locale['Message']}
									</FormLabel>
									<Textarea
										placeholder={locale['Type in a message']}
										value={message}
										onChange={e => setMessage(e.target.value)}
									/>
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight='400' fontSize='md' my={1}>
										{locale['Link for the attachment file']}
									</FormLabel>
									<Textarea
										placeholder={attachmentFileUrl}
										value={attachmentFileUrl}
										//onChange={e => setMessage(e.target.value)}
										isDisabled={true}
									/>
								</FormControl>
							</VStack>
							<Text color='red'>{error}</Text>
						</DrawerBody>
						<DrawerFooter>
							<Box mt={2} w='full'>
								<AlertBox
									isOpen={isAlertOpen}
									onClose={customClose}
									Message={alertMessage}
								/>
								<HStack justifyContent='flex-end'>
									<Button
										variant='outline'
										size='md'
										borderRadius='sm'
										colorScheme={localStorage.getItem('color')}
										bg='white.400'
										w='140px'
										ml={1}
										// onClick={onClose}
										onClick={() => {
											sectionDetailedTrack({
												category: GA_CATEGORY_DIALOG_BOX,
												action: 'Export Table',
												label: 'Reset',
											})
											resetForm()
										}}
									>
										{locale['Reset']}
									</Button>
									<Button
										variant='solid'
										size='md'
										borderRadius='sm'
										colorScheme={localStorage.getItem('color')}
										bg={localStorage.getItem('color')}
										w='140px'
										ml={1}
										onClick={() => {
											sectionDetailedTrack({
												category: GA_CATEGORY_DIALOG_BOX,
												action: 'Export Table',
												label: 'Send Mail',
											})
											onSendMail()
										}}
									>
										{locale['Send Email']}
									</Button>
								</HStack>
							</Box>
						</DrawerFooter>
					</DrawerContent>
				</Drawer>
			) : null}
			<Card maxH='250px'>
				<Box pb='5px'>
					<Flex>
						<Box>
							<Text fontSize='md' color='black' textTransform='capitalize'>
								{processName}
							</Text>
							<Tag
								borderRadius='full'
								variant='outline'
								size='sm'
								color={downloadTextColor}
								borderColor={
									isDownload ? localStorage.getItem('color') : 'gray.100'
								}
								bgColor={downloadTagColor}
								mt='5px'
							>
								{row.report_status_name}
							</Tag>
						</Box>

						<Spacer />
						<Box>
							<Box as='span' pr={2}>
								{!!isDownload ? (
									<span>
										<IconButton
											borderRadius='20%'
											boxShadow='md'
											size='sm'
											mx={2}
											bgColor='white'
											onClick={() => {
												sectionDetailedTrack({
													category: GA_CATEGORY_DIALOG_BOX,
													action: 'Export Table',
													label: 'Download AWS File',
												})
												downloadAwsFile(row.data_entity_text_1)
											}}
											icon={<BiDownload size='25' />}
										/>
										<IconButton
											bg='white'
											borderRadius='md'
											boxShadow='md'
											aria-label='Send E-mail'
											size='md'
											_focus={{
												bg: 'secondary',
											}}
											_hover={{
												bg: 'secondary',
											}}
											icon={<Icon as={BiMailSend} w={5} h={5} />}
											onClick={() => {
												sectionDetailedTrack({
													category: GA_CATEGORY_DIALOG_BOX,
													action: 'Export Table',
													label: 'Send Mail On Open',
												})
												onOpenSendEmail(row.data_entity_text_1)
											}}
										/>
									</span>
								) : null}
							</Box>
							<Icon
								cursor='pointer'
								as={!isOpen ? MdArrowDropDown : MdArrowDropUp}
								w={7}
								h={7}
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_DIALOG_BOX,
										action: 'Export Table',
										label: 'Toggle',
									})
									onToggle()
								}}
							/>
						</Box>
					</Flex>
				</Box>
				<VStack w='full'>
					<HStack justifyContent='space-around' w='full'>
						<Text fontSize='sm' flex={1} mb={1}>
							{locale['Start Date']} :
							<Text
								as='span'
								fontWeight='400'
								color={localStorage.getItem('color')}
							>
								{getFormatDDMMYYYY(row.report_next_start_datetime)}
							</Text>
						</Text>
						<Text fontSize='sm' flex={1} mb={1}>
							{' '}
							{locale['End Date']} :
							<Text
								as='span'
								fontWeight='400'
								color={localStorage.getItem('color')}
							>
								{getFormatDDMMYYYY(row.report_next_end_datetime)}
							</Text>
						</Text>
					</HStack>
					<HStack justifyContent='space-around' w='full'>
						<Text fontSize='sm' flex={1} mb={1}>
							{' '}
							{locale['Created Date']} :
							<Text
								as='span'
								fontWeight='400'
								color={localStorage.getItem('color')}
							>
								{formatDDMMYYYYHHMM(row.report_created_datetime)}
							</Text>
						</Text>
						<Text fontSize='sm' flex={1} mb={1}>
							{' '}
							{locale['Status Update Date']} :
							<Text
								as='span'
								fontWeight='400'
								color={localStorage.getItem('color')}
							>
								{formatDDMMYYYYHHMM(row.log_datetime)}
							</Text>
						</Text>
					</HStack>
				</VStack>

				<Collapse in={isOpen} animateOpacity>
					<Divider py={1} />
					<HStack as='span' px={1} mt={2} shadow='md' spacing='4'>
						<Text as='span' fontSize='14px' mb={1}>
							{locale['Form Name']} :
							{formName.map((name, index) => {
								return (
									<Text
										as='span'
										key={name + index}
										fontWeight='400'
										color={localStorage.getItem('color')}
									>
										{name.form_names ? name.form_names : name}{' '}
										<Text as='span' color='gray.500'>
											{' '}
											,{' '}
										</Text>
									</Text>
								)
							})}
						</Text>
					</HStack>
				</Collapse>
			</Card>
		</>
	)
}

export default ExportListItem
